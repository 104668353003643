<template>
  <div style="padding: 10px">
    <div style="margin: 10px 0">
      <el-date-picker value-format="YYYY-MM-DD" format="YYYY-MM-DD" v-model="search.eventDate"></el-date-picker>
      <el-select v-model="search.clubCode" clearable filterable placeholder="俱乐部" style="width: 10%">
        <el-option
                v-for="item in clubCodeDict"
                :key="item.clubCode"
                :label="item.clubName"
                :value="item.clubCode">
        </el-option>
      </el-select>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
      <el-button style="margin-left: 5px" @click="resetData">重置</el-button>
      <!--      <span style="color: #409EFF;font-weight: bold;margin-left: 5px;text-align: center">快速查询：</span>-->
      <el-button type="success" style="margin-left: 5px" @click="loadClub(item.clubCode)" :key="item.clubCode"
                 v-for="item in clubList" title="快速筛选当天该俱乐部的活动数据，非当天使用条件筛选">{{ item.clubName }}
      </el-button>
      <el-button type="primary" style="margin-left: 5px" @click="exportTemplate" title="活动人员名单及俱乐部信息，建议当天下载">模板下载</el-button>
      <el-upload
              :action=this.importUrl
              :on-success="handleUploadSuccess"
              :show-file-list=false
              :limit="1"
              accept='.xlsx'
              style="display: inline-block; margin: 0 10px"
      >
        <el-button type="primary" title="先下载模板然后进行导入，兔子活动类型是1，天马活动类型是2">活动数据导入</el-button>
      </el-upload>
      <el-button type="primary" style="margin-left: 5px" @click="compute" title="导入完成后进行积分和排名计算">计算</el-button>
    </div>
    <!--搜索区域-->
    <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%">
      <el-table-column
              prop="eventDate"
              label="活动日期">
      </el-table-column>
      <el-table-column
              prop="activityType"
              :formatter="activityTypeFormatter"
              label="活动类型">
      </el-table-column>
      <el-table-column
              prop="clubCode"
              :formatter="clubCodeFormatter"
              label="活动所在俱乐部">
      </el-table-column>
      <el-table-column
              prop="accountId"
              :formatter="accountIdFormatter"
              label="账号名称">
      </el-table-column>
      <el-table-column
              prop="allScore"
              :formatter="allScoreFormatter"
              label="总得分">
      </el-table-column>
      <el-table-column
              prop="contributionScore"
              :formatter="contributionScoreFormatter"
              label="贡献分">
      </el-table-column>
      <el-table-column
              prop="killScore"
              :formatter="killScoreFormatter"
              label="击杀分">
      </el-table-column>
      <el-table-column
              prop="contribution"
              :formatter="contributionFormatter"
              label="玉兔贡献/天马伤害">
      </el-table-column>
      <el-table-column
              prop="killCount"
              :formatter="killCountFormatter"
              label="玉兔击杀/天马击杀">
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>
    <el-dialog title="计算" v-model="dialogVisible" width="30%">
      <el-form :model="form">
        <el-date-picker value-format="YYYY-MM-DD" format="YYYY-MM-DD" v-model="form.date"></el-date-picker>
      </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="computeSave">确 定</el-button>
          </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: 'activityInfoUpload',
  components: {},
  data() {
    return {
      importUrl: "http://182.92.223.123:9090/activityInfo/import",
      exportUrl: "http://182.92.223.123:9090/activityInfo/exportTemplate",
      clubList: [],
      dialogVisible: false,
      clubCodeDict: [],
      accountInfoDict: [],
      form: {
        date: '',
      },
      userId: '',
      loading: true,
      search: {},
      query: {},
      currentPage: 1,
      pageSize: 20,
      total: 0,
      tableData: [],
    }
  },
  created() {
    this.load()
    let userStr = sessionStorage.getItem("user") || "{}"
    let user = JSON.parse(userStr)
    this.userId = user.id;
  },
  mounted() {
    this.getAccountInfoList();
    this.getClubCodeDict();
  },
  methods: {
    getAccountInfoList() {
      request.get("/accountInfo/listNoPage", {}).then(res => {
        this.loading = false
        this.accountInfoDict = res.data
      })
    },
    getClubCodeDict() {
      request.get("/clubInfo/listNoPage", {}).then(res => {
        this.loading = false
        this.clubCodeDict = res.data
        this.clubCodeDict.forEach(e => {
          if (e.inUse === 1) {
            this.clubList.push(e)
          }
        })
      })
    },
    handleUploadSuccess(res) {
      if (res.code === "0") {
        this.$message.success("导入成功")
        this.load()
      }
    },
    exportTemplate() {
      location.href = this.exportUrl;
    },
    compute() {
      this.dialogVisible = true;
    },
    computeSave(){
      this.loading = true
      if (this.form.date === '' || this.form.date == null) {
        this.$message({type:"warning",message:"日期不能为空！"})
      }
      request.post("/activityInfo/compute", this.form).then(res => {
        if (res.success) {
          this.$message({
            type: "success",
            message: "计算完成"
          })
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
        this.load() // 刷新表格的数据
        this.loading = false
        this.dialogVisible = false  // 关闭弹窗
      })
    },
    resetData() {
      this.search = {}
      this.load()
    },
    loadClub(value) {
      this.search = {}
      this.search.clubCode = value
      this.search.eventDate = new Date();
      this.load();
    },
    load() {
      this.loading = true
      const searchForm = {...this.search}
      let obj = Object.assign(searchForm, this.query)
      let reqData = {
        ...obj
      }
      reqData.current = this.currentPage
      reqData.size = this.pageSize
      request.get("/activityInfo/list", {
        params: reqData
      }).then(res => {
        this.loading = false
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(pageSize) {   // 改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {  // 改变当前页码触发
      this.currentPage = pageNum
      this.load()
    },
    accountIdFormatter(row) {
      if (row.accountId === null) {
        return "无";
      }
      const list = this.accountInfoDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === row.accountId) {
            return list[i].accountName
          }
        }
      }
    },
    allScoreFormatter(row) {
      if (row.allScore === 0) {
        return "未参赛";
      }
      return row.allScore
    },
    contributionScoreFormatter(row) {
      if (row.contributionScore === 0) {
        return "无"
      }
      return row.contributionScore
    },
    killScoreFormatter(row) {
      if (row.killScore === 0) {
        return "无"
      }
      return row.killScore
    },
    killCountFormatter(row) {
      if (row.killCount === 0) {
        return "无"
      }
      return row.killCount
    },
    clubCodeFormatter(row) {
      if (row.clubCode === null || row.clubCode === -1) {
        return "无";
      }
      const list = this.clubCodeDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].clubCode === row.clubCode) {
            return list[i].clubName
          }
        }
      }
    },
    activityTypeFormatter(row) {
      if (row.activityType === null) {
        return "无";
      }
      const list = [{"type": 1, "name": "广寒宫"}, {"type": 2, "name": "御马监"}];
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].type === row.activityType) {
            return list[i].name
          }
        }
      }
    },
    contributionFormatter(row) {
      if (row.contribution === null || row.contribution === 0) {
        return "无";
      }
      if (row.activityType === 1) {
        return row.contribution + "分";
      }
      return row.contribution + "兆"
    },
  }
}
</script>
